import React, { useState, useEffect } from 'react'

type ImageData = {
  id: string
  data: string
}

type Props = {
  getCsrfToken: () => string
}

export default function ImageApprovalComponent({ getCsrfToken }: Props) {
  const [decision, setDecision] = useState<string | null>(null)
  const [imageData, setImageData] = useState<ImageData | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [countdown, setCountdown] = useState(5)

  const handleDecision = (approved: boolean) => {
    setDecision(approved ? 'approved' : 'denied')
    fetch(process.env.API_URL + '/ir', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': getCsrfToken()
      },
      credentials: 'include',
      body: JSON.stringify({
        approved: approved,
        id: imageData!.id
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
      })
  }

  const handleDownload = () => {
    fetch(process.env.API_URL + '/download', {
      method: 'GET',
      headers: {
        'x-csrf-token': getCsrfToken()
      },
      credentials: 'include'
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'download.zip')
        document.body.appendChild(link)
        link.click()
        link.parentNode?.removeChild(link)
      })
  }

  useEffect(() => {
    nextImage()
  }, [])

  useEffect(() => {
    if (decision === null) return;
    const timer = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1)
    }, 1000);

    setTimeout(() => {
      setImageData(null)
      setDecision(null)
      setIsLoading(true)
      setCountdown(5)
      nextImage()
      clearInterval(timer)
    }, 5000);

    return () => clearInterval(timer)
  }, [decision])

  const nextImage = () => {
    setIsLoading(true)
    try {
      fetch(process.env.API_URL + '/next-ir', {
        method: 'GET',
        headers: {
          'x-csrf-token': getCsrfToken()
        },
        credentials: 'include'
      })
        .then(response => response.json())
        .then(data => {
          setImageData(data)
          setIsLoading(false)
        })
        .catch(error => {
          setIsLoading(false);
        })
    } catch (error) {
      console.log(error);
    }


  }

  const loadingSpinner = () => (
    <div className="flex justify-center items-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#fdfdfd]"></div>
    </div>
  )

  const imageControl = () => {
    if (isLoading) {
      return loadingSpinner()
    }
    if (!imageData) {
      return <div className='text-[#fdfdfd] text-center'>No images to approve</div>
    }
    return (
      <>
        <img
          src={imageData.data}
          alt='Image to be approved'
          className='w-full h-auto mb-4 rounded'
        />
        <br />
        <div className='flex justify-center space-x-4 mb-4'>
          <button
            onClick={() => handleDecision(true)}
            className='px-4 py-2 bg-[#222222] text-[#fdfdfd] rounded-md text-sm font-medium hover:bg-[#333333] focus:outline-none focus:ring-2 focus:ring-[#222222] focus:ring-opacity-50 transition'
          >
            Approve
          </button>
          <button
            onClick={() => handleDecision(false)}
            className='px-4 py-2 bg-[#222222] text-[#fdfdfd] rounded-md text-sm font-medium hover:bg-[#333333] focus:outline-none focus:ring-2 focus:ring-[#222222] focus:ring-opacity-50 transition'
          >
            Deny
          </button>
        </div>
      </>
    )
  }

  return (
    <div className='max-w-md mx-auto mt-8 p-6 bg-[#101010] rounded-lg shadow-lg shadow-[#101010] border-2 border-[#101010]'>
      <div className='flex justify-center space-x-4 m-4'>
      <h1 className='text-2xl font-bold text-[#fdfdfd]'>Image Approval</h1>
        <button onClick={handleDownload} className='px-4 py-2 bg-[#fedb00] text-[#222222] rounded-md text-2xl font-bold hover:bg-[#ed1076] hover:text-[#ffffff] focus:outline-none focus:ring-2 focus:ring-[#222222] focus:ring-opacity-50 transition'>
          Download Images for Carousel
        </button>
      </div>
      
      {imageControl()}

      {decision && (
        <div
          className={`p-4 rounded ${decision === 'approved' ? 'bg-[#222222]' : 'bg-[#222222]'}`}
        >
          <div className='text-[#fdfdfd] text-center'>
            {decision === 'approved' ? 'Image Approved' : 'Image Denied'}
          </div>
          <div className='text-[#fdfdfd] text-center'>
            You have {decision} this image.
            <br />
            Next image will appear in {countdown} seconds.
          </div>
        </div>
      )}

    </div>
  )
}